// import { useTheme } from '@mui/material/styles'

export const Logo = ({
  width = '180',
  height = '64',
}: {
  width?: string
  height?: string
}) => {
  // const theme = useTheme()
  // const fillColor = theme.palette.primary.main

  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 320 64'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_427_457)'>
        <path d='M310.009 49V13H316.135V49H310.009Z' fill='#0B0B1D' />
        <path
          d='M271 49L280.369 13H295.556L304.925 49H298.645L296.225 39.7697H279.7L277.281 49H271ZM281.142 34.0039H294.784L290.82 18.7658H285.106L281.142 34.0039Z'
          fill='#0B0B1D'
        />
        <path
          d='M242.001 49V27.9961H238.5V23.0026H242.001V17.3398H247.664V23.0026H255V27.9961H247.664V44.0064H255.5V49H242.001Z'
          fill='#0B0B1D'
        />
        <path
          d='M210 49V12.964H215.611V26.7606H215.766C216.521 25.5251 217.619 24.4955 219.06 23.6718C220.536 22.8138 222.269 22.3848 224.26 22.3848C227.452 22.3848 230.064 23.363 231.883 25.3192C233.736 27.2411 234.663 29.9524 234.663 33.453V49H229V34.0708C229 31.84 228.451 30.1755 227.353 29.0772C226.254 27.9447 224.637 27.3784 222.716 27.3784C221.377 27.3784 220.176 27.7044 219.112 28.3565C218.048 28.9743 217.19 29.8837 216.538 31.0849C215.92 32.2518 215.611 33.5903 215.611 35.1004V49H210Z'
          fill='#0B0B1D'
        />
        <path
          d='M181 59V54.0064H198.161V44.9845H198.007C197.767 45.3964 197.269 45.9627 196.514 46.6834C195.759 47.4041 194.764 48.039 193.528 48.5882C192.327 49.1373 190.92 49.4118 189.307 49.4118C187.076 49.4118 185.017 48.8284 183.129 47.6615C181.276 46.4946 179.8 44.8987 178.702 42.8739C177.604 40.8147 177.055 38.4809 177.055 35.8726C177.055 33.2986 177.604 30.982 178.702 28.9228C179.8 26.8636 181.276 25.2677 183.129 24.1351C185.017 22.9683 187.076 22.3848 189.307 22.3848C190.92 22.3848 192.327 22.6594 193.528 23.2085C194.764 23.7233 195.759 24.3411 196.514 25.0618C197.269 25.7825 197.767 26.3488 198.007 26.7606H198.161V23.0026H203.824V59H181ZM190.542 44.3668C191.949 44.3668 193.236 44.0236 194.403 43.3372C195.57 42.6165 196.48 41.6212 197.132 40.3513C197.818 39.0472 198.161 37.5543 198.161 35.8726C198.161 34.1909 197.818 32.7151 197.132 31.4453C196.48 30.1411 195.57 29.1459 194.403 28.4595C193.236 27.7387 191.949 27.3784 190.542 27.3784C189.101 27.3784 187.78 27.7387 186.578 28.4595C185.411 29.1459 184.485 30.1411 183.798 31.4453C183.146 32.7151 182.82 34.1909 182.82 35.8726C182.82 37.5543 183.146 39.0472 183.798 40.3513C184.485 41.6212 185.411 42.6165 186.578 43.3372C187.78 44.0236 189.101 44.3668 190.542 44.3668Z'
          fill='#0B0B1D'
        />
        <path
          d='M166 49V23.0026H171.611V49H166ZM168.78 18.8842C167.853 18.8842 167.047 18.5581 166.361 17.9061C165.674 17.2197 165.331 16.4303 165.331 15.538C165.331 14.6457 165.674 13.8735 166.361 13.2214C167.047 12.535 167.853 12.1918 168.78 12.1918C169.741 12.1918 170.565 12.535 171.251 13.2214C171.938 13.8735 172.281 14.6457 172.281 15.538C172.281 16.4303 171.938 17.2197 171.251 17.9061C170.565 18.5581 169.741 18.8842 168.78 18.8842Z'
          fill='#0B0B1D'
        />
        <path
          d='M149.708 49.6177C147.306 49.6177 145.247 49.2059 143.531 48.3822C141.815 47.5242 140.442 46.4431 139.412 45.139C138.383 43.8005 137.662 42.3419 137.25 40.7632H143.119C143.565 41.8957 144.337 42.8567 145.435 43.6461C146.568 44.4011 147.992 44.7786 149.708 44.7786C151.596 44.7786 152.969 44.4869 153.827 43.9035C154.685 43.32 155.114 42.5307 155.114 41.5354C155.114 40.9176 154.856 40.42 154.341 40.0425C153.827 39.6306 153.174 39.3046 152.385 39.0643C151.596 38.8241 150.498 38.5495 149.09 38.2407C146.894 37.7602 145.092 37.2625 143.685 36.7477C142.312 36.2329 141.111 35.4436 140.081 34.3797C139.052 33.2814 138.537 31.8057 138.537 29.9524C138.537 28.6139 138.932 27.3612 139.721 26.1943C140.545 25.0275 141.729 24.1008 143.273 23.4144C144.852 22.728 146.739 22.3848 148.936 22.3848C152.299 22.3848 154.959 23.1742 156.915 24.7529C158.872 26.2973 160.107 28.2021 160.622 30.4672H154.856C154.513 29.5749 153.844 28.8198 152.848 28.2021C151.853 27.55 150.549 27.2239 148.936 27.2239C147.906 27.2239 147.048 27.3612 146.362 27.6358C145.676 27.876 145.161 28.2021 144.818 28.6139C144.474 29.0257 144.303 29.4719 144.303 29.9524C144.303 30.5701 144.56 31.0849 145.075 31.4968C145.59 31.8743 146.242 32.1832 147.031 32.4234C147.821 32.6637 148.919 32.9382 150.326 33.2471C152.522 33.7276 154.307 34.2252 155.68 34.74C157.087 35.2548 158.305 36.0613 159.335 37.1596C160.365 38.2235 160.879 39.6821 160.879 41.5354C160.879 43.0111 160.45 44.3668 159.592 45.6023C158.734 46.8035 157.447 47.7816 155.731 48.5367C154.05 49.2574 152.042 49.6177 149.708 49.6177Z'
          fill='#0B0B1D'
        />
        <path
          d='M108 49V23.0026H113.611V26.7606H113.766C114.521 25.5251 115.619 24.4955 117.06 23.6718C118.536 22.8138 120.269 22.3848 122.26 22.3848C124.896 22.3848 128.064 23.3629 129.883 25.3192C131.736 27.2411 132.663 29.9524 132.663 33.453V49H127V34.0708C127 31.84 126.451 30.1755 125.353 29.0772C124.254 27.9447 122.637 27.3784 120.716 27.3784C119.377 27.3784 118.176 27.7044 117.112 28.3565C116.048 28.9743 115.19 29.8837 114.538 31.0849C113.92 32.2518 113.611 33.5903 113.611 35.1004V49H108Z'
          fill='#0B0B1D'
        />
        <path d='M94.998 49V13H101.124V49H94.998Z' fill='#0B0B1D' />
        <path
          d='M56.5 6.9762L62.2391 3.46549C62.5723 3.26169 63 3.50146 63 3.89202V15.7293C63 15.9129 62.8993 16.0818 62.7378 16.1691L46.4883 24.9546L55.8203 16.7526C56.2524 16.3729 56.5 15.8256 56.5 15.2504V6.9762Z'
          fill='#0B0B1D'
        />
        <path
          d='M46.2668 37.6604L55.9232 47.4407C56.2928 47.815 56.5 48.3199 56.5 48.8459V56.6649L62.2226 60.4815C62.5548 60.7031 63 60.4649 63 60.0656V48.3635C63 48.1909 62.9109 48.0305 62.7644 47.9392L46.2668 37.6604Z'
          fill='#0B0B1D'
        />
        <path
          d='M47.5 54.3904L54.1439 61.1314C54.4578 61.4499 55 61.2276 55 60.7804V48.8459C55 48.7144 54.9482 48.5882 54.8558 48.4946L43.3973 36.889L47.3934 48.6338C47.464 48.8413 47.5 49.0589 47.5 49.278V54.3904Z'
          fill='#0B0B1D'
        />
        <path
          d='M43.3578 25.709L47.3605 15.5648C47.4527 15.331 47.5 15.082 47.5 14.8307V8.97956L54.1594 2.7823C54.4792 2.4847 55 2.71149 55 3.14833V15.2504C55 15.3942 54.9381 15.531 54.8301 15.6259L43.3578 25.709Z'
          fill='#0B0B1D'
        />
        <path
          d='M38 17.3477L45.6204 1.77578C45.7135 1.58539 46 1.65172 46 1.8637V14.8307C46 14.8935 45.9882 14.9558 45.9651 15.0142L41 27.5977V34.5L45.9734 49.117C45.991 49.1688 46 49.2233 46 49.278V62.066C46 62.2816 45.7058 62.3446 45.6175 62.1479L38 45.1765C38 45.1765 38 37.655 38 17.3477Z'
          fill='#0B0B1D'
        />
        <path
          d='M30 1.5C30 1.22386 30.2239 1 30.5 1H33.5C33.7761 1 34 1.22386 34 1.5V62.5C34 62.7761 33.7761 63 33.5 63H30.5C30.2239 63 30 62.7761 30 62.5V1.5Z'
          fill='#0B0B1D'
        />
        <path
          d='M26 17.3477L18.3796 1.77578C18.2865 1.58539 18 1.65172 18 1.8637V14.8307C18 14.8935 18.0118 14.9558 18.0349 15.0142L23 27.5977V34.5L18.0266 49.117C18.009 49.1688 18 49.2233 18 49.278V62.066C18 62.2816 18.2942 62.3446 18.3825 62.1479L26 45.1765C26 45.1765 26 37.655 26 17.3477Z'
          fill='#0B0B1D'
        />
        <path
          d='M16.5 54.3904L9.85607 61.1314C9.54216 61.4499 8.99996 61.2276 8.99996 60.7804L8.99996 48.8459C8.99996 48.7144 9.05177 48.5882 9.14416 48.4946L20.6027 36.889L16.6066 48.6338C16.536 48.8413 16.5 49.0589 16.5 49.278V54.3904Z'
          fill='#0B0B1D'
        />
        <path
          d='M20.6422 25.709L16.6395 15.5648C16.5473 15.331 16.5 15.082 16.5 14.8307V8.97956L9.84059 2.7823C9.5208 2.4847 8.99996 2.71149 8.99996 3.14833V15.2504C8.99996 15.3942 9.06187 15.531 9.16988 15.6259L20.6422 25.709Z'
          fill='#0B0B1D'
        />
        <path
          d='M7.5 6.9762L1.76091 3.46549C1.42774 3.26169 1 3.50146 1 3.89202V15.7293C1 15.9129 1.10066 16.0818 1.2622 16.1691L17.5117 24.9546L8.17967 16.7526C7.74764 16.3729 7.5 15.8256 7.5 15.2504V6.9762Z'
          fill='#0B0B1D'
        />
        <path
          d='M17.7332 37.6604L8.0768 47.4407C7.70723 47.815 7.5 48.3199 7.5 48.8459L7.5 56.6649L1.77743 60.4815C1.44516 60.7031 1 60.4649 1 60.0656V48.3635C1 48.1909 1.08907 48.0305 1.2356 47.9392L17.7332 37.6604Z'
          fill='#0B0B1D'
        />
      </g>
      <defs>
        <clipPath id='clip0_427_457'>
          <rect width='320' height='64' fill='white' />
        </clipPath>
      </defs>
    </svg>
  )
}
